import { FC } from 'react'
import NextProgressBar from 'nextjs-progressbar'
import { useTheme } from 'styled-components'

const ProgressBar: FC = () => {
  const theme = useTheme()
  return (
    <NextProgressBar
      color={theme.colors.arapawa}
      height={2}
      options={{ showSpinner: false }}
    />
  )
}

export default ProgressBar
